.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 5px;
    padding: 10px;
    width: 100%;
    max-height: 400px;
    /* min-width: 135px; */
    background-color: white;
    z-index: 1;
    border: 1px solid rgba(60, 51, 79, 1);
  }
  .product:hover{
    transform: scale(1.05);
    box-shadow: 0 0 11px rgba(33,33,33); 
    cursor: pointer;
  }
  
  .product__rating {
    display: flex;
  }
  .product__sold{
    font-family: Medium !important;
    margin-bottom: 10px;
  }
  .product >img {
    max-height: 230px;
    width: 100%;
    object-fit: contain;
    
    border: 1px solid black;
    
    padding: 2px;
    
  }
  .animea .product > button{
    margin-top: 50px !important;
  }
  @media (max-width: 550px){
    .product button{
      margin-top: 0px !important;
    }
  }
  @media (max-width: 1300px){
    .product button{
      margin-top: 50px !important;
    }
  }
  .product > button {
    background: #f0c14b;
    /* border: solid; */
    margin-top: 32px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111; 
  }
  .icon_heart{
    color: red !important;
  }
  
  .product__price {
    margin-top: 5px;
    color: #D32300;
  }
  .product__origin{
    text-decoration-line: line-through;
    margin-right: 2px;
  }
  .product__price,.product__origin{
    display: inline-block;
  }
  
  .product__info {
    height: 100px;
    
    margin-top: 5px;
  }
  .product__info .tit{
    text-align: center;
  }
  @media (max-width: 768px){
    .product{
      margin-left: 0;

    }
    .product__price {
      margin-top: -5px;
      
    }
    .product > button{
      margin-top: 45px !important;
    }
    .animea .product > button{
      margin-top: 70px !important;
    }
    .box{
      padding-top: 0 !important;
    }
    .box h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box1{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box1 h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box2{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box2 h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box3{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box3 h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box4{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box4 h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box5{
      padding-top: 0 !important;
      width: 95px;
      height: 95px;
    }
    .box5 h2{
      margin-top:65px;
      font-size: 11px;
      text-align: center;
    }
    .box>p,
    .box1>p,
    .box2>p,
    .box3>p,
    .box4>p,
    .box5>p{
      display: none !important;
    }
  }
.clm{
  text-decoration: none;
  color: rgba(60, 51, 79, 1);
}