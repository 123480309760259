.payment{
    background-color: white;
}
.payment__container >h1{
    text-align: center;
    padding: 10px;
    font-weight: 400;
    background-color: rgb(234, 237, 237);
    border-bottom: 1px solid lightgray;
}
.payment__container > h1 a{
    text-decoration: none;
}
.payment__section{
    display: flex;
    padding: 20px;
    margin: 0 20px;
    border-bottom: 1px solid lightgray;
}
.payment__title{
    flex: 0.2;
}
.payment__address,
.payment__items,
.payment__details{
    flex: 0.8;
}
.payment > h2{
    display: flex;
}